import React, { useState, useEffect } from "react"
import * as S from "./styled"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import {
    selectFinalAnswer,
    selectRevealdLetter,
    revealAllLetters,
    selectAllLettersReveald,
    selectLamaText,
    setLamaText,
} from "../../../store/gameSlice"
import { useQuestions } from "../../../hooks/useQuestions"
import GridContainer from "../../styled/GridContainer"

const Board = ({ props: { additionalInformations, header } }: Props) => {
    const dispatch = useAppDispatch()
    const finalAnswer = useAppSelector(selectFinalAnswer)
    const revealdLetters = useAppSelector(selectRevealdLetter)
    const newestLetter = revealdLetters[revealdLetters.length - 1]
    const questionsLength = useQuestions().length
    const allLettersReveald = useAppSelector(selectAllLettersReveald)
    const lamaText = useAppSelector(selectLamaText)
    const getBoardIcon = () => {
        let boardIcon = null
        switch (questionsLength) {
            case 8:
                boardIcon = <S.BoardIcon8 />
                break
            case 11:
                boardIcon = <S.BoardIcon11 />
                break
            case 12:
                boardIcon = <S.BoardIcon12 />
                break

            default:
                break
        }
        return boardIcon
    }

    const boardIcon = getBoardIcon()

    const onChange = (value: string, id: string) => {
        const input = document.getElementById(id) as HTMLInputElement
        input.value = value
        if (value === "") {
            input.classList.add("hidden-input")

            return
        }
        let nextInputToFocus = 0
        const inputs = document.querySelectorAll(
            ".hidden-input"
        ) as NodeListOf<HTMLInputElement>
        input.classList.remove("hidden-input")
        if (inputs.length === 1) {
            checkResult()
        }

        inputs.forEach((input, index) => {
            if (input.id === id) {
                nextInputToFocus = index + 1 === inputs.length ? 0 : index + 1
            }
        })
        inputs[nextInputToFocus].focus()
    }

    const checkResult = () => {
        const letterInputs = document.querySelectorAll(
            ".letter-input"
        ) as NodeListOf<HTMLInputElement>
        const finalResult: string[] = []
        letterInputs.forEach((input, index) => {
            const inputValue = input.value.toLocaleLowerCase()
            finalResult.push(inputValue)
            input.disabled = true

            if (finalAnswer[index] !== inputValue) {
                input.classList.add("hidden-input", "wrong-letter")
            }
        })

        setTimeout(() => {
            const wrongLetters = document.querySelectorAll(
                ".wrong-letter"
            ) as NodeListOf<HTMLInputElement>
            wrongLetters.forEach(input => {
                input.value = ""
                input.disabled = false
                input.classList.remove("wrong-letter")
            })
        }, 2000)

        if (finalResult.join("") === finalAnswer) {
            dispatch(
                setLamaText(
                    "<strong>Gratuluję bardzo!</strong><br/>Masz już całe hasło 😊"
                )
            )

            dispatch(revealAllLetters())
            letterInputs.forEach(input => {
                input.classList.add("right-letter")
            })
        } else {
            dispatch(
                setLamaText(
                    "<strong>Próbuj dalej!</strong><br/>Musi się udać 😊"
                )
            )
        }
    }

    useEffect(() => {
        if (!allLettersReveald) return

        const letterInputs = document.querySelectorAll(
            ".letter-input"
        ) as NodeListOf<HTMLInputElement>
        letterInputs.forEach(input => {
            input.classList.add("right-letter")
        })
        dispatch(
            setLamaText(
                "<strong>Gratuluję bardzo!</strong><br/>Masz już całe hasło 😊"
            )
        )
    }, [allLettersReveald])

    useEffect(() => {
        if (!newestLetter || allLettersReveald) return
        const texts = {
            1: "Moc jest z Tobą!",
            2: "Wybornie milordzie!",
            3: "This is the way!",
            4: "Ogień!",
            5: "Bullseye!",
            6: "Like a boss!",
            7: "Nice!",
            8: "Punkt dla Gryffindoru!",
            9: "Keep on keeping on!",
        }

        const getRandomNumber = (min: number, max: number) => {
            return Math.floor(Math.random() * (max - min + 1)) + min
        }
        dispatch(setLamaText(`${texts[getRandomNumber(1, 9)]}`))
    }, [newestLetter])

    return (
        <GridContainer>
            <S.Wrapper>
                <div dangerouslySetInnerHTML={{ __html: header }} />
                <S.BoardWrapper variation={questionsLength}>
                    {boardIcon}
                    {finalAnswer.split("").map((letter, index) => {
                        if (revealdLetters.includes(letter)) {
                            return (
                                <S.Input
                                    className="letter-input"
                                    newest={newestLetter === letter}
                                    disabled
                                    value={letter}
                                />
                            )
                        }
                        if (letter === " ") {
                            return (
                                <S.Input
                                    className="letter-input"
                                    disabled
                                    value={" "}
                                />
                            )
                        }
                        return (
                            <S.Input
                                className="hidden-input letter-input"
                                id={letter + index}
                                onChange={e =>
                                    onChange(e.target.value, letter + index)
                                }
                                placeholder="_"
                                maxLength={1}
                            />
                        )
                    })}
                    <S.LamaIcon />
                    <S.LamaBox
                        visible={lamaText !== ""}
                        dangerouslySetInnerHTML={{
                            __html: lamaText,
                        }}
                    />
                </S.BoardWrapper>

                <S.AdditionalInfo
                    dangerouslySetInnerHTML={{ __html: additionalInformations }}
                />
            </S.Wrapper>
        </GridContainer>
    )
}

interface Props {
    props: {
        additionalInformations: string
        header: string
    }
}

export default Board

import React from "react"
import * as S from "./styled"
import { graphql } from "gatsby"
import Template from "../Template"
import SectionParser from "./section-parser/SectionParser"
import useData from "./useData"
import { setup } from "../../../store/gameSlice"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { useEffect } from "react"

const Page = ({ data }: Props) => {
    const { title, description, sortedSections } = useData(data)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setup())
    }, [])
    return (
        <Template title={title} description={description}>
            {data && <SectionParser sections={sortedSections} />}
        </Template>
    )
}

export const query = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            acfMainPage {
                title
                description
            }
            wpChildren {
                nodes {
                    slug
                    ... on WpPage {
                        title
                        menuOrder
                        ...Children
                        ...Intro
                        ...Wheel
                        ...Board
                    }
                }
            }
        }
    }
`

interface Props {
    data: any
}

export default Page

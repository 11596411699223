import styled, { keyframes, css } from "styled-components"
import WheelIcon8 from "@icons/wheel-8.svg"
import WheelIcon11 from "@icons/wheel-11.svg"
import WheelIcon12 from "@icons/wheel-12.svg"
import Marker from "@icons/marker.svg"
import LamaHand from "@icons/lama-hand.svg"
import { breakpoints } from "@breakpoints"

const animation = keyframes`
0% {
    transform:rotate(-10deg);
}

20% {
    transform:rotate(0deg);
}

100% {
    transform:rotate(-10deg);
}
`

const Wrapper = styled.div`
    position: relative;
    padding-top: 230px;
    overflow: hidden;
    margin-top: -90px;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 20%;
        bottom: 0;
        background-color: #fff;
        z-index: -1;
    }

    ${breakpoints.md} {
        margin-top: -100px;
    }

    ${breakpoints.lg} {
        margin-top: -150px;
    }

    img {
        position: absolute;
        left: 0;
        top: 100px;
        bottom: 100px;
        width: 100%;
        height: auto;
        z-index: -1;
    }
`

const wheelStyles = css`
    position: absolute;
    top: 105px;
    left: calc(50% - 125px);
    width: 250px;
    height: 250px;
    transform-origin: center;
    transition: 2s;
    z-index: -1;

    ${breakpoints.sm} {
        left: calc(50% - 200px);
        width: 400px;
        height: 400px;
        top: 30px;
    }
`

const WheelSvg8 = styled(WheelIcon8)<{ $isSpinning: boolean }>`
    ${wheelStyles}
    transform: ${({ $isSpinning }) =>
        $isSpinning ? `rotate(${$isSpinning}deg)` : "rotate(0deg)"};
`

const WheelSvg11 = styled(WheelIcon11)<{ $isSpinning: boolean }>`
    ${wheelStyles}
    transform: ${({ $isSpinning }) =>
        $isSpinning ? `rotate(${$isSpinning}deg)` : "rotate(0deg)"};
`

const WheelSvg12 = styled(WheelIcon12)<{ $isSpinning: boolean }>`
    ${wheelStyles}
    transform: ${({ $isSpinning }) =>
        $isSpinning ? `rotate(${$isSpinning}deg)` : "rotate(0deg)"};
`

const MarkerSvg = styled(Marker)<{ spin: boolean; $isSpinning: boolean }>`
    position: absolute;
    top: 65px;
    left: calc(50% - 25px);
    width: 50px;
    height: auto;
    transform-origin: top center;
    animation-iteration-count: 10;
    transform: ${({ spin }) => (spin ? "rotate(20deg)" : "rotate(0deg)")};
    transition: 3s;
    animation: ${({ $isSpinning }) =>
        $isSpinning
            ? css`
                 10 ${animation} .2s;
              `
            : ""};
    z-index: 10;

    ${breakpoints.sm} {
        top: 0;
        left: calc(50% - 30px);
        width: 60px;
    }
`

const LamaHandSvg = styled(LamaHand)<{ $isSpinning: boolean }>`
    display: none;
    position: absolute;
    top: -50px;
    left: 50%;
    width: 300px;
    z-index: -1;
    transform: ${({ $isSpinning }) =>
        $isSpinning ? "translate(145px, 215px)" : "translate(0px, 0px)"};
    transition: 1s;

    ${breakpoints.md} {
        display: block;
    }
`

export { Wrapper, WheelSvg8, WheelSvg11, WheelSvg12, MarkerSvg, LamaHandSvg }

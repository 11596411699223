import React, { useState, useEffect } from "react"
import * as S from "./styled"
import background from "../../../assets/images/dark-background.png"
import QuestionsBox from "./questions-box/QuestionsBox"
import { useQuestions } from "../../../hooks/useQuestions"
import GridContainer from "../../styled/GridContainer"

const Wheel = ({ props: { header, instruction, buttonText } }: Props) => {
    const [isSpinning, setIsSpinning] = useState<false | number>(false)
    const [markerSpin, setMarkerSpin] = useState(false)
    const questions = useQuestions()

    const getWheelIcon = () => {
        let wheelIcon

        switch (questions.length) {
            case 8:
                wheelIcon = <S.WheelSvg8 $isSpinning={isSpinning} />
                break
            case 11:
                wheelIcon = <S.WheelSvg11 $isSpinning={isSpinning} />
                break
            case 12:
                wheelIcon = <S.WheelSvg12 $isSpinning={isSpinning} />
                break

            default:
                break
        }
        return wheelIcon
    }
    const wheelIcon = getWheelIcon()

    useEffect(() => {
        if (!isSpinning) return

        setMarkerSpin(true)

        setTimeout(() => {
            setMarkerSpin(false)
        }, 1000)
    }, [isSpinning])

    return (
        <S.Wrapper>
            <img src={background} />
            <S.MarkerSvg spin={markerSpin} $isSpinning={isSpinning} />
            {wheelIcon}
            <S.LamaHandSvg $isSpinning={isSpinning} />
            <GridContainer>
                <QuestionsBox
                    setIsSpinning={setIsSpinning}
                    startText={header}
                    instruction={instruction}
                    buttonText={buttonText}
                />
            </GridContainer>
        </S.Wrapper>
    )
}

interface Props {
    props: {
        header: string
        instruction: string
        buttonText: string
    }
}
export default Wheel

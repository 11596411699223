import React, { useEffect, useState } from "react"
import Lama from "@icons/lama.svg"
import * as S from "./styled"
import GridContainer from "../../styled/GridContainer"
import Instructions from "./instructions/Instructions"

const Intro = ({
    props: { header, hello, instructions, instructionsHeader },
}: Props) => {
    const [boxVisible, setBoxVisible] = useState(false)

    useEffect(() => {
        setBoxVisible(true)
    }, [])

    return (
        <GridContainer>
            <S.Grid className="grid-cols-12">
                <div className="col-span-6">
                    <S.Hello dangerouslySetInnerHTML={{ __html: hello }} />
                    <S.Header dangerouslySetInnerHTML={{ __html: header }} />
                    <Instructions
                        header={instructionsHeader}
                        instructions={instructions}
                    />
                </div>
                <S.LamaWrapper boxVisible={boxVisible} className="col-span-6">
                    <Lama />
                </S.LamaWrapper>
                <div className="col-span-12">
                    <Instructions
                        header={instructionsHeader}
                        instructions={instructions}
                        mobile
                    />
                </div>
            </S.Grid>
        </GridContainer>
    )
}

interface Props {
    props: {
        header: string
        hello: string
        instructions: Array<{
            instruction: string
        }>
        instructionsHeader: string
    }
}

export default Intro

import React, { createElement } from "react"
import { config } from "./config"

const SectionParser = ({ sections }: Props) => {
    const parsedSections = sections.map((section, index) => {
        const {
            acfChildren: { template },
        } = section
        if (!config[template]) return
        return createElement(config[template].section, {
            key: index,
            props: {
                ...section[config[template].acf],
                id: index,
            },
        })
    })
    return <>{parsedSections}</>
}

interface Props {
    sections: Array<{
        acfChildren: {
            template: string
        }
        id: string
        title: string
        menuOrder: number
    }>
}

export default SectionParser

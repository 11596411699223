import React, { useState, useEffect, useRef } from "react"
import * as S from "./styled"
import Button from "../../../common/button/Button"
import { useQuestions } from "../../../../hooks/useQuestions"
import {
    revealLetter,
    selectRevealdLetter,
    selectAllLettersReveald,
    setLamaText,
} from "../../../../store/gameSlice"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import { isBrowser } from "../../../../utils/isBrowser"

const QuestionsBox = ({
    setIsSpinning,
    startText,
    instruction,
    buttonText,
}: Props) => {
    const dispatch = useAppDispatch()
    const allLettersReveald = useAppSelector(selectAllLettersReveald)
    const reveladLetters = useAppSelector(selectRevealdLetter)
    const [questionIndex, setQuestionIndex] = useState<false | number>(false)
    const [header, setHeader] = useState(startText)
    const [subheader, setSubheader] = useState("start")
    const [instructionText, setInstructionText] = useState(instruction)
    const [gameStarted, setGameStarted] = useState(false)
    const [showCorrectAnswer, setShowCorrectAnswer] = useState<false | string>(
        false
    )
    const questions = useQuestions()
    const scrollRef = useRef(null)
    const isLastQuestion = questionIndex === questions.length - 1

    const startGame = () => {
        setIsSpinning("720")
        setGameStarted(true)
        setQuestionIndex(0)
        setSubheader(`${0 + 1}/${questions.length}`)
        setHeader(questions[0].question)
    }

    const checkAnswer = (answer: string, isCorrect: boolean) => {
        setShowCorrectAnswer(answer)

        const texts = {
            1: "Cholibka!",
            2: "Było blisko!",
            3: "Nie poddawaj się!",
            4: "Prawie!",
            5: "Zawsze wiatr w oczy!",
            6: "Rock'y po ciosie zawsze wstawał!",
            7: "Ciemność, widzę ciemność!",
            8: "I cały misterny plan w ...!",
        }

        const getRandomNumber = (min: number, max: number) => {
            return Math.floor(Math.random() * (max - min + 1)) + min
        }

        if (!isCorrect && !isLastQuestion) {
            dispatch(setLamaText(`${texts[getRandomNumber(1, 8)]}`))
        }

        if (isCorrect) {
            dispatch(revealLetter())
        } else if (isLastQuestion) {
            fillLettersManually()
        }
    }

    const fillLettersManually = () => {
        setQuestionIndex(false)
        setInstructionText(
            "<strong>Jeśli nie udało Ci się odkryć całego hasła, możesz spróbować</strong> je zgadnąć i wpisać literki ręcznie 😊"
        )
        setSubheader(`Super`)
        setHeader("To nie koniec!")
    }

    const endGame = () => {
        scrollToQuestionBox()
        setQuestionIndex(false)
        setInstructionText(
            "<strong>Teraz zapisz hasło na kuponie ze stoiska</strong> i wrzuć go do szklanej kuli!"
        )
        setSubheader(`Gratulujemy`)
        setHeader("Brawo! Masz to!")
    }

    const nextQuestion = () => {
        const wheelPart = 360 / questions.length
        const wheelDeg = 720 - (questionIndex + 1) * wheelPart

        setIsSpinning(`${wheelDeg}`)
        //@ts-ignore
        setQuestionIndex(questionIndex + 1)
    }

    const scrollToQuestionBox = () => {
        if (!isBrowser()) return

        if (window.innerWidth > 1024) return

        const y =
            //@ts-ignore
            scrollRef.current.getBoundingClientRect().top +
            window.pageYOffset +
            -40

        window.scrollTo({ top: y, behavior: "smooth" })
    }

    useEffect(() => {
        setShowCorrectAnswer(false)
        if (questionIndex) {
            setSubheader(`${questionIndex + 1}/${questions.length}`)
            setHeader(questions[questionIndex].question)
        }
    }, [questionIndex])

    useEffect(() => {
        if (!allLettersReveald && isLastQuestion) {
            fillLettersManually()
        }
    }, [reveladLetters])

    useEffect(() => {
        if (allLettersReveald) {
            endGame()
        }
    }, [allLettersReveald])

    return (
        <S.Wrapper ref={scrollRef} id="test">
            <S.Subheader dangerouslySetInnerHTML={{ __html: subheader }} />
            <S.Header dangerouslySetInnerHTML={{ __html: header }} />
            {questionIndex === false && (
                <S.Instruction
                    dangerouslySetInnerHTML={{ __html: instructionText }}
                />
            )}
            {questionIndex !== false && (
                <S.Answers>
                    {questions[questionIndex].answers.map(
                        ({ answer, isCorrect }, index) => (
                            <S.RadioWrapper
                                key={index}
                                success={showCorrectAnswer ? isCorrect : false}
                                error={
                                    showCorrectAnswer === answer
                                        ? !isCorrect
                                        : false
                                }
                            >
                                <S.Radio
                                    checked={showCorrectAnswer && isCorrect}
                                    disabled={showCorrectAnswer !== false}
                                    onClick={() =>
                                        checkAnswer(answer, isCorrect)
                                    }
                                    id={answer}
                                    type="radio"
                                />
                                <S.Label
                                    htmlFor={answer}
                                    dangerouslySetInnerHTML={{ __html: answer }}
                                />
                            </S.RadioWrapper>
                        )
                    )}
                </S.Answers>
            )}
            {!isLastQuestion && questionIndex !== false && (
                <Button
                    text={"Następne pytanie"}
                    onClick={() => nextQuestion()}
                    disabled={!showCorrectAnswer}
                />
            )}
            {!gameStarted && !allLettersReveald && (
                <Button text={buttonText} onClick={() => startGame()} />
            )}
        </S.Wrapper>
    )
}

interface Props {
    setIsSpinning: (deg: string) => void
    startText: string
    instruction: string
    buttonText: string
}

export default QuestionsBox

import React from "react"
import styled from "styled-components"

const MarginWrapper = styled.section`
    margin: "30px 0";
`

const WithMargin = (Component: React.FC<any>) => {
    return function WithMarginComponent({ ...props }) {
        return (
            <MarginWrapper>
                <Component {...props} />
            </MarginWrapper>
        )
    }
}

export default WithMargin

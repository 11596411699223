import { cssVariables } from "@cssVariables"
import styled, { css, keyframes } from "styled-components"
import BoardSvg8 from "@icons/board-8.svg"
import BoardSvg11 from "@icons/board-11.svg"
import BoardSvg12 from "@icons/board-12.svg"
import LamaSvg from "@icons/small-lama.svg"
import { breakpoints } from "@breakpoints"

const animation = keyframes`
0%{
    background-color: #fff;
    color: #fff;
    -webkit-text-fill-color: #fff;

}
30% {
    background-color: ${cssVariables.colors.success};
}

80% {
    background-color: ${cssVariables.colors.success};
    color: #fff;
    -webkit-text-fill-color: #fff;

}

100% {
    background-color: #fff;
}
`

const BoardIcon8 = styled(BoardSvg8)`
    position: relative;
`

const BoardIcon11 = styled(BoardSvg11)`
    position: relative;
`

const BoardIcon12 = styled(BoardSvg12)`
    position: relative;
`

const BoardWrapper = styled.div<{ variation: 8 | 11 | 12 }>`
    position: relative;
    margin: 5px auto;
    max-width: 1000px;

    svg:first-of-type {
        width: 100%;
        height: auto;
    }

    input {
        position: absolute;
        top: 35.9%;
        height: 16.4%;
        width: 4.5%;

        &:focus {
            outline: none;
            background-color: ${cssVariables.colors.main};

            &.right-letter {
                background-color: ${cssVariables.colors.success};
            }
        }
    }

    input:nth-of-type(1) {
        left: 20%;
    }

    input:nth-of-type(2) {
        left: 26.3%;
    }

    input:nth-of-type(3) {
        left: 32.7%;
        display: ${({ variation }) => (variation === 12 ? "block" : "none")};
    }

    input:nth-of-type(4) {
        left: 38.8%;
        display: ${({ variation }) => (variation === 12 ? "none" : "block")};
    }

    input:nth-of-type(5) {
        left: 45.2%;
    }

    input:nth-of-type(6) {
        left: 51.47%;
    }

    input:nth-of-type(7) {
        display: ${({ variation }) => (variation === 11 ? "none" : "block")};
        left: 57.8%;
    }

    input:nth-of-type(8) {
        left: 64.16%;
        display: ${({ variation }) => (variation === 12 ? "none" : "block")};
    }

    input:nth-of-type(9) {
        top: ${({ variation }) => (variation === 12 ? "55.1%" : "35.9%")};
        left: ${({ variation }) => (variation === 12 ? "20%" : "70.5%")};
    }

    input:nth-of-type(10) {
        top: ${({ variation }) => (variation === 12 ? "55.1%" : "35.9%")};
        left: ${({ variation }) => (variation === 12 ? "26.3%" : "76.8%")};
    }

    input:nth-of-type(11) {
        display: ${({ variation }) => (variation === 12 ? "block" : "none")};
        top: ${({ variation }) => (variation === 12 ? "55.4%" : "55.1%")};
        left: ${({ variation }) => (variation === 12 ? "32.7%" : "26.3%")};
    }

    input:nth-of-type(12) {
        top: ${({ variation }) => (variation === 12 ? "55.4%" : "55.1%")};
        left: ${({ variation }) => (variation === 12 ? "38.9%" : "20%")};
    }

    input:nth-of-type(13) {
        top: ${({ variation }) => (variation === 12 ? "55.4%" : "55.1%")};
        left: ${({ variation }) => (variation === 12 ? "45.1%" : "26.3%")};
    }

    input:nth-of-type(14) {
        display: ${({ variation }) => (variation === 12 ? "block" : "none")};
        top: 55.4%;
        left: ${({ variation }) => (variation === 12 ? "51.4%" : "19.5%")};
    }

    input:nth-of-type(15) {
        top: 55.4%;
        left: ${({ variation }) => (variation === 12 ? "57.8%" : "38.9%")};
    }

    input:nth-of-type(16) {
        top: 55.4%;
        left: ${({ variation }) => (variation === 12 ? "64.2%" : "45.1%")};
    }

    input:nth-of-type(17) {
        top: 55.4%;
        left: ${({ variation }) => (variation === 12 ? "70.6%" : "51.4%")};
    }

    input:nth-of-type(18) {
        top: ${({ variation }) => (variation === 12 ? "55.1%" : "55.4%")};
        left: ${({ variation }) => (variation === 12 ? "76.8%" : "57.8%")};
    }

    input:nth-of-type(19) {
        top: ${({ variation }) => (variation === 12 ? "55.1%" : "55.4%")};
        left: ${({ variation }) => (variation === 12 ? "83.1%" : "64.2%")};
    }

    input:nth-of-type(20) {
        top: 55.4%;
        left: ${({ variation }) => (variation === 12 ? "83.1%" : "70.6%")};
    }

    input:nth-of-type(21) {
        top: 55.1%;
        left: ${({ variation }) => (variation === 12 ? "83.1%" : "76.8%")};
    }
`

const Wrapper = styled.div`
    text-align: center;
    margin: 15px 0;
`

const Space = styled.div`
    display: none;
`

const Input = styled.input<{ newest?: boolean }>`
    width: 50px;
    height: 100px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    transition: 0.3s;
    background: transparent;

    ${breakpoints.md} {
        font-size: 46px;
    }

    &::placeholder {
        color: ${cssVariables.colors.dark};
    }

    &:disabled {
        -webkit-text-fill-color: ${cssVariables.colors.dark};
    }

    &:not(.right-letter) {
        animation: ${({ newest }) =>
            newest
                ? css`
                      ${animation} 2s
                  `
                : "none"};
    }

    &.wrong-letter {
        background-color: ${cssVariables.colors.error};
    }

    &.right-letter {
        background-color: ${cssVariables.colors.success};
        color: #fff;
        -webkit-text-fill-color: #fff;
    }
`

const LamaIcon = styled(LamaSvg)`
    position: absolute;
    left: 50%;
    bottom: -100px;
    width: 100px;
    height: auto;

    ${breakpoints.md} {
        width: 200px;
        left: 80%;
        bottom: -160px;
    }

    ${breakpoints.lg} {
        left: 90%;
        bottom: 0;
    }
`

const LamaBox = styled.div<{ visible: boolean }>`
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    position: absolute;
    left: 58%;
    bottom: -65px;
    border-radius: 0px 40px 40px 40px;
    border: 1px solid #fff;
    background-color: ${cssVariables.colors.dark};
    padding: 10px;
    color: #fff;
    transition: 0.3s;
    font-size: 10px;

    ${breakpoints.md} {
        left: 85%;
        bottom: -50px;
        min-width: 120px;
    }

    ${breakpoints.lg} {
        left: 95%;
        bottom: 100px;
        min-width: 140px;
    }
`

const AdditionalInfo = styled.span`
    width: 50%;
    display: block;
    text-align: left;
    font-size: ${cssVariables.fonts.normal};
    margin: 20px 0 50px;

    ${breakpoints.md} {
        width: auto;
        text-align: center;
    }
`

export {
    Wrapper,
    Input,
    BoardIcon8,
    BoardIcon11,
    BoardIcon12,
    BoardWrapper,
    Space,
    LamaIcon,
    LamaBox,
    AdditionalInfo,
}

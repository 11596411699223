import styled from "styled-components"
import { cssVariables } from "@cssVariables"
import { breakpoints } from "@breakpoints"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: absolute; */
    top: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border: 4px solid ${cssVariables.colors.dark};
    padding: 10px;
    max-width: 950px;
    width: 100%;
    background-color: #fff;
    z-index: 10;

    ${breakpoints.lg} {
        padding: 20px;
    }
`

const Subheader = styled.span`
    font-size: ${cssVariables.fonts.small};
    font-weight: 600;
    text-transform: uppercase;

    ${breakpoints.md} {
        font-size: ${cssVariables.fonts.medium};
    }
`

const Instruction = styled.div`
    margin: 15px 0;
    text-align: center;
`

const Header = styled.h2`
    font-size: ${cssVariables.fonts.big};
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;

    ${breakpoints.md} {
        /* font-size: ${cssVariables.fonts.xBig}; */
    }
`

const Radio = styled.input`
    position: relative;
    appearance: none;
    margin: 0;
    min-width: 20px;
    height: 20px;
    border: 1px solid ${cssVariables.colors.grayDark};
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 3px;
        width: 12px;
        height: 12px;
        opacity: 0;
        border-radius: 15px;
        background: ${cssVariables.colors.main};
    }

    &:checked {
        &:after {
            opacity: 1;
        }
    }
`

const Label = styled.label`
    cursor: pointer;
    display: flex;
    font-size: ${cssVariables.fonts.small};
`

const RadioWrapper = styled.div<{ success: boolean; error: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    color: ${({ success, error }) =>
        success
            ? cssVariables.colors.success
            : error
            ? cssVariables.colors.error
            : "#000"};
    font-weight: ${({ success, error }) => (success || error ? 700 : 400)};

    ${Radio} {
        border-color: ${({ success, error }) =>
            success
                ? cssVariables.colors.success
                : error
                ? cssVariables.colors.error
                : "#000"};

        &:after {
            background: ${({ success, error }) =>
                success
                    ? cssVariables.colors.success
                    : error
                    ? cssVariables.colors.error
                    : "#000"};
        }
    }
`

const Answers = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`

export {
    Wrapper,
    Instruction,
    Subheader,
    Header,
    Radio,
    Label,
    RadioWrapper,
    Answers,
}

const useData = (data: PageData) => {
    const {
        wpChildren,
        acfMainPage: { title, description },
    } = data.wpPage
    const sortedSections = wpChildren.nodes.sort(
        (a, b) => a.menuOrder - b.menuOrder
    )
    return {
        title,
        description,
        sortedSections,
    }
}

interface PageData {
    wpPage: {
        wpChildren: {
            nodes: Array<{
                acfChildren: {
                    template: string
                    removeRelative: boolean
                }
                id: string
                title: string
                menuOrder: number
            }>
        }
        acfMainPage: {
            title: string
            description: string
        }
    }
}

export default useData

import { graphql, useStaticQuery } from "gatsby"
import { useAppSelector } from "../store/hooks"
import { selectFinalAnswer } from "../store/gameSlice"

export const useQuestions = () => {
    const finalAnswer = useAppSelector(selectFinalAnswer)
    const findUnique = (str: string) => {
        return [...str].reduce((acc, curr) => {
            return acc.includes(curr) ? acc : acc + curr
        }, "")
    }
    const uniqueLetters = findUnique(finalAnswer).replace(/\s/g, "").length

    const data: useQuestions = useStaticQuery(
        graphql`
            query {
                wp {
                    questions {
                        acf {
                            questions {
                                question
                                answers {
                                    answer
                                    isCorrect
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wp.questions.acf.questions.slice(0, uniqueLetters)
}

interface useQuestions {
    wp: {
        questions: {
            acf: {
                questions: Array<{
                    question: string
                    answers: Array<{
                        answer: string
                        isCorrect: boolean
                    }>
                }>
            }
        }
    }
}

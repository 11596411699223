import { Intro, Wheel, Board } from "../../../sections"
import WithMargin from "../../../hoc/withMargin"

export const config = {
    "intro": {
        section: WithMargin(Intro),
        acf: "acfIntro",
    },
    "wheel": {
        section: WithMargin(Wheel),
        acf: "acfWheel",
    },
    "board": {
        section: WithMargin(Board),
        acf: "acfBoard",
    },
}
